export let BANKSTATEMENT_API_URL = "";
export let LOANS_API_URL = "";
export let DOC_CLASSIFICATION_API_URL = "";
export let USER_API_URL = "";
export let RULE_ENGINE_API_URL = "";
export let RULE_VALIDATOR_API_URL = "";
export let CUSTOMER_API_URL = "";
export let COMMON_API_URL = "";
export let DATA_EXTRACTION_HIL_API_URL = "";

if (typeof window !== "undefined") {
  switch (window.location.hostname) {
    case "localhost":
      // BANKSTATEMENT_API_URL = "https://localhost:7196/";
      BANKSTATEMENT_API_URL = "https://bankincomedev.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-dev.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-dev.azurewebsites.net/";
      USER_API_URL = "https://msuiteuser-dev.azurewebsites.net/api/v1";
      RULE_ENGINE_API_URL = "https://ruleengine-ard5cuehcmejhqg7.southindia-01.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://rulesenginevalidator-dev.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-dev.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-dev.azurewebsites.net/";
      DATA_EXTRACTION_HIL_API_URL = "https://msuitedehil-dev.azurewebsites.net/api/v1/";
      break;

    case "qa.morgej.com":
    case "www.qa.morgej.com":
      BANKSTATEMENT_API_URL = "https://bankincomeqa.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-qa.azurewebsites.net/";
      USER_API_URL = "https://msuiteuser-qa.azurewebsites.net/api/v1";
      RULE_ENGINE_API_URL = "https://msuiteruleengine-qa.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://msuiterulesreview-qa.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-qa.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-qa.azurewebsites.net/";
      break;

    case "dev.morgej.com":
    case "www.dev.morgej.com":
      BANKSTATEMENT_API_URL = "https://bankincomedev.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-dev.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-dev.azurewebsites.net/";
      USER_API_URL = "https://msuiteuser-dev.azurewebsites.net/api/v1";
      RULE_ENGINE_API_URL = "https://ruleengine-ard5cuehcmejhqg7.southindia-01.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://rulesenginevalidator-dev.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-dev.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-dev.azurewebsites.net/";
      DATA_EXTRACTION_HIL_API_URL = "https://msuitedehil-dev.azurewebsites.net/api/v1/";
      break;

    case "bsic.usdemo.msuite.ai":
    case "www.bsic.usdemo.msuite.ai":
    case "usdemo.msuite.ai":
    case "www.usdemo.msuite.ai":
      BANKSTATEMENT_API_URL = "https://us-bankbackend-demo.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-usdemo.azurewebsites.net/";
      USER_API_URL = "https://msuiteuser-usdemo.azurewebsites.net/api/v1";
      RULE_ENGINE_API_URL = "https://msuiteruleengine-usdemo.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://msuiteruleengine-USDemo.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-usdemo.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-usdemo.azurewebsites.net/";
      break;
    case "bsic.indemo.msuite.ai":
    case "www.bsic.indemo.msuite.ai":
      BANKSTATEMENT_API_URL = "https://bankbackend-demo.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      RULE_ENGINE_API_URL = "https://msuiteruleengine-uat.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://msuiteruleengine-UAT.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-UAT.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-UAT.azurewebsites.net/";
      break;

    case "bsic.uat.msuite.ai":
    case "www.bsic.uat.msuite.ai":
      BANKSTATEMENT_API_URL = "https://bankbackend-uat.azurewebsites.net/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-uat.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-uat.azurewebsites.net/api/v1/";
      USER_API_URL = "https://msuiteuser-uat.azurewebsites.net/api/v1";
      RULE_ENGINE_API_URL = "https://msuiteruleengine-uat.azurewebsites.net/api/v1/rules/";
      RULE_VALIDATOR_API_URL = "https://msuiteruleengine-UAT.azurewebsites.net/";
      CUSTOMER_API_URL = "https://msuitecustomer-UAT.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-UAT.azurewebsites.net/";
      break;

    default:
      BANKSTATEMENT_API_URL = "https://bankincomeqa.azurewebsites.net/";
      LOANS_API_URL = "https://msuiteloans-qa.azurewebsites.net/api/v1/";
      DOC_CLASSIFICATION_API_URL = "https://msuitedocument-qa.azurewebsites.net/";
      RULE_ENGINE_API_URL = "https://msuiteruleengine-qa.azurewebsites.net/api/v1/rules/";
      CUSTOMER_API_URL = "https://msuitecustomer-qa.azurewebsites.net/";
      COMMON_API_URL = "https://msuitecommon-qa.azurewebsites.net/";
  }
}

export const environment = {
  production: false,
  doc_classification_api_url: DOC_CLASSIFICATION_API_URL,
  backstatement_api_url: BANKSTATEMENT_API_URL,
  loans_api_url: LOANS_API_URL,
  user_api_url: USER_API_URL,
  rule_engine_api_url: RULE_ENGINE_API_URL,
  rule_validator_api_url: RULE_VALIDATOR_API_URL,
  customer_api_url: CUSTOMER_API_URL,
  common_api_url: COMMON_API_URL,
  data_extraction_hil_api_uri: DATA_EXTRACTION_HIL_API_URL,
  msalConfig: {
    auth: {
      clientId: "7d9b204f-da2c-4cda-b214-7d55a6e3bd5a",
    },
  },
  apiConfig: [
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Read",
        "https://flatworldsolb2c.onmicrosoft.com/common/Common.Write",
      ],
      uri: COMMON_API_URL,
    },
    {
      scopes: ["https://flatworldsolb2c.onmicrosoft.com/bsic/BSIC.Read"],
      uri: BANKSTATEMENT_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Read",
        "https://flatworldsolb2c.onmicrosoft.com/customers/Customers.Write",
      ],
      uri: CUSTOMER_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Read",
        "https://flatworldsolb2c.onmicrosoft.com/storage/Files.Write",
      ],
      uri: "https://blob-service-dev.azurewebsites.net/api/v2",
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Users/Users.Write",
      ],
      uri: USER_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Read",
        "https://flatworldsolb2c.onmicrosoft.com/loans/Loans.Write",
      ],
      uri: LOANS_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Rules-Review/Rules.Review.Write",
      ],
      uri: RULE_VALIDATOR_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/Documents/Document.Read",
        "https://flatworldsolb2c.onmicrosoft.com/Documents/Document.Write",
      ],
      uri: DOC_CLASSIFICATION_API_URL,
    },
    {
      scopes: [
        "https://flatworldsolb2c.onmicrosoft.com/dehil/dehil.Read",
        "https://flatworldsolb2c.onmicrosoft.com/dehil/dehil.Write",
      ],
      uri: DATA_EXTRACTION_HIL_API_URL,
    },
  ],
  defaultScope: ["https://flatworldsolb2c.onmicrosoft.com/api/user.read"],
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1_Signin",
      resetPassword: "B2C_1_reset",
      editProfile: "B2C_1_edit_profile",
    },
    authorities: {
      signUpSignIn: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_Signin",
      },
      resetPassword: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_reset",
      },
      editProfile: {
        authority: "https://flatworldsolb2c.b2clogin.com/flatworldsolb2c.onmicrosoft.com/B2C_1_edit_profile",
      },
    },
    authorityDomain: "flatworldsolb2c.b2clogin.com",
  },
};
